export const BASE = {
  api: {
    base_url: 'https://dunas-dashboard-api-74hf2txj6q-ue.a.run.app',
    // base_url: 'http://localhost:3066',
    user_validate: '/user/validate',
    user_create: '/user',
    terminals: '/terminals',
    validate_register_number: '/multiclubes/user/validate',
    get_user_access: '/milanga/findByUser',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    delete_user: '/user',
    find_user_newc: '/user/newc/document',
    remove_biometry: '/biometry',
    sync_token: '/biometry/forceSync',
    // access data
    access_approver: '/approvers',
    visitor_company: '/companies',
    visitory_type: '/visitor-type',
    access_reports: '/access-control/report',
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    get_log_from_gate: '/access-control/today',
    get_all_logs_for_the_day: '/access-control/monitoring',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  local_api: {
    base_url: 'https://6f996a2d5f61.sa.ngrok.io',
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    // access data
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_user: {
    base_url: 'https://api-user-management-dunas-74hf2txj6q-ue.a.run.app',
    user_validate: '/user/validate',
    user_create: '/user',
    user_edit: '/user',
    validate_register_number: '/multiclubes/user/validate',
    validate_entrance: '/multiclubes/entrance/validate/accessCode',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_update: '/visitor',
    visitor_find_by_document: '/biometry/find/visitors',
    access_create: '/access/create',
    access_edit: '/access/edit',
    access_get: '/access',
    log_create: '/logs',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    sync_token: '/biometry/forceSync',
    visitor_force_sync: '/biometry/visitor/forceSync',
    // ADEMIR
    hik_get_cards: '/hikvision/status/card',
    find_user_with_biometry: '/biometry/find/users',
    remove_biometry: '/biometry',
    get_passport_count: '/user/passport',
    force_update: '/biometry/internal/forceApproval',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_game: {
    base_url: 'https://dunas-game-api-74hf2txj6q-ue.a.run.app',
    game_report: '/game-report/auto',
    game_sync_report: '/game-report/get-synced-images',
    get_game_tickets_count: '/tickets/count',
    get_games: '/game',
    active_game: '/game/active',
    game_distribution_by_game: '/distribution/game',
    game_distribution: '/distribution',
    ticket_audit: '/sync/audit',
    tickets: '/tickets',
    get_game_config: '/game/config/company',
    set_game_config: '/game/config',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',

  },
  api_mc: {
    base_url: 'https://multiclubes-service-prod-74hf2txj6q-ue.a.run.app',
    sync_accessCode: '/multiclubes/update'
  },
  api_fc: {
    base_url: 'https://prod-futebolcard-intergration-74hf2txj6q-rj.a.run.app',
    get_access: '/tickets/access',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',

  },
  api_report: {
    base_url: 'https://access-control-service-prod-74hf2txj6q-rj.a.run.app',
    report_access: '/access/report',
    access_by_type: '/access/monitor/type',
    access_total: '/access/monitor'
  }
}
