import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { VisitorReport } from '../components/visitorReport'

function VisitorsList() {
  return (
    <>
      <Row>
        <Col>
          <VisitorReport />
        </Col>
      </Row>
    </>
  )
}

export default VisitorsList
