import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { UserCreate } from '../components/userCreate'
import { ListUsers } from '../components/listUsers'
import { UserEdit } from '../components/editUser'

function EditUser() {
  const auth = sessionStorage.getItem('auth')

  const currentUser = sessionStorage.getItem('role')

  if (auth !== 'true') {
    window.location.href = '/'
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <UserEdit />
        </Col>
      </Row>
    </>
  )
}

export default EditUser
