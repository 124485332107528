import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'

import { VisitorCreate } from '../components/visitorCreate'
import { GuestCreate } from '../components/guestCreate'
import { UserEdit } from '../components/editUser'
import { UserCreate } from '../components/userCreate'
import { FindClubeUserToUpdate } from '../components/findClubeUserToUpdate'
import { UpdateUser } from '../components/updateUser'

function Profile() {
  const auth = sessionStorage.getItem('auth')
  const role = sessionStorage.getItem('role')

  if (role !== 'admin' && role !== 'generalEditor' && role !== 'supervisingEditor') {
    window.location.href = '/dashboard';
  }
  
  if (auth !== 'true') {
    window.location.href = '/'
  }
  const [photoState, setphotoState] = useState<string>('')
  const params = new URLSearchParams(window.location.search) // id=123
  const userId = params.get('id')


  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
    if(!params.get('id')) {
      window.location.href = '/dashboard'
    }
  }, [])

  return (
    <>
      <Row>
        <Col md={6} xl={6}>
          <UpdateUser />
        </Col>
        <Col md={6} xl={6}>
        </Col>
      </Row>
    </>
  )
}

export default Profile
