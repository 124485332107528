import React from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

interface Props {
  totalData: any
}

export function ExitStatus({ totalData }: Props) {
  return (
    <Card className="card-social">
      <Card.Body className="border-bottom">
        <div className="row align-items-center justify-content-center">
          <div className="col-auto">
            {/* <i className="fab fa-google-plus-g text-c-red f-36" /> */}
          </div>
          <div className="col text-right">
            <h3>
              {totalData} <span className="text-muted">Saíram hoje</span>
            </h3>
          </div>
        </div>
      </Card.Body>
      {/* <Card.Body>
        <div className="row align-items-center justify-content-center card-active">
          <div className="col-6">
            <h6 className="text-center m-b-10">
              <span className="m-r-5 text-c-green">Entraram:</span>
              25,998
            </h6>
           <div className="progress">
              <div
                className="progress-bar progress-c-theme"
                style={{ width: '80%', height: '6px' }}
                aria-valuenow={80}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div> 
          </div>
          <div className="col-6">
            <h6 className="text-center  m-b-10">
              <span className="m-r-5 text-c-red">Saíram:</span>
              900
            </h6>
            <div className="progress">
              <div
                className="progress-bar progress-c-theme2"
                style={{ width: '50%', height: '6px' }}
                aria-valuenow={80}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div> 
          </div>
        </div>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Recipient's username"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <InputGroup className="input-group-append">
            <Button>Button</Button>
          </InputGroup>
        </InputGroup> 
      </Card.Body> */}
    </Card>
  )
}
