import React, { Component, useCallback, useEffect, useRef, useState } from 'react'
import {
  FormControl,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  InputGroup,
  Button,
  Pagination,
  Modal
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import './terminals.sass'
import { Link } from 'react-router-dom'
import { utils, writeFile } from 'xlsx'
import { TerminalForm } from '../components/terminalForm'
import { TerminalListItem } from '../components/terminalListItem'

function TerminalsPage() {
  const [terminalList, setTerminalList] = useState<any[]>([]);

  const getTerminalList = () => {
    fetch(`${BASE.api.base_url}${BASE.api.terminals}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
    })
      .then((res) => res.json())
      .then((response) => {
        
        if (Array.isArray(response)) {
          setTerminalList(response);
        } else {
          console.error('Expected an array but got:', response);
          setTerminalList([]); 
        }
      })
      .catch((error) => {
        console.log(error);
        setTerminalList([]); 
      });
  };

  useEffect(() => {
    getTerminalList();
  }, []);

  return (
    <>
      <div className="game-tickets-header">
        <Row>
          <Col xl={4}>
            <Card.Title as="h5">Catracas Mapeadas </Card.Title>
          </Col>
        </Row>

        <Table responsive striped className='terminal-list'>
          <thead>
            <tr>
              <th>IP Raspbery</th>
              <th>IP Dispositivo Facial</th>
              <th>MacAddress</th>
              <th>Tag da Catraca</th>
              <th>Portão</th>
              <th>Setor</th>
              <th className='text-center'>Marca Dispositivo Facial</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(terminalList) && terminalList.length > 0 ? (
              terminalList.map((item: any, index: any) => (
                <TerminalListItem terminalData={item} key={index} />
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">Nenhuma catraca encontrada</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default TerminalsPage;