import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import ptBR from 'date-fns/locale/pt-BR'
import './Game.sass'
import LoadingHandler from '../../Biometric/StatesComponents/LoadingHandler'
import { utils, read, writeFile } from 'xlsx';

function GameDistribution() {


    const [loader, setLoader] = useState(false)
    const [tableData, setTableData] = useState<any[]>([]);

    const [newDataRow, setNewDataRow] = useState({ gateId: "", referenceId: "" });

    const [gameList, setGameList] = useState<any[]>([])

    const [gameId, setGameId] = useState('')

    const getGameList = () => {
        setLoader(true)
        fetch(`${BASE.api_game.base_url}${BASE.api_game.get_games}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setGameList(response)
                if (response && response[0].gameCode) {
                    setTimeout(() => {
                        setParcode(response[0].gameCode)
                        setLoader(false)
                    }, 300);
                }
                getDistributionData(response[0].id)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    function getGameCodeById(id: any) {
        console.log(gameList)
        console.log(' - > > > > > > > > | | | ')
        for (let i = 0; i < gameList.length; i++) {
            console.log(gameList[i].id ,id)
            if (gameList[i].id === id) {
                console.log(gameList[i].gameCode)
                return gameList[i].gameCode;
            }
        }

        // return null;
    }

    const getDistributionData = (gameId: string) => {


        setLoader(true)

        fetch(`${BASE.api_game.base_url}${BASE.api_game.game_distribution_by_game}/${gameId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setGameId(gameId)
                setTableData(response.data)
                if (gameId) {
                    setParcode(getGameCodeById(gameId))
                }
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    const currentUser = sessionStorage.getItem('role')

    if (currentUser === 'default') {
        window.location.href = '/dashboard'
    }
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    const role = sessionStorage.getItem('role')


    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }

    const onSubmit = (data: any) => {
        setLoader(true)
        setDisableSign(true)

        fetch(`${BASE.api_game.base_url}${BASE.api_game.game_distribution}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "section": data.section,
                "allianzSection": 'xxx',
                "sectionDescription": "",
                "gate": data.gate,
                "type": "bepass",
                status: 'active',
                "gameId": gameId
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setLoader(false)
                getDistributionData(gameId)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    const updateData = (row: any) => {
        setLoader(true)
        fetch(`${BASE.api_game.base_url}${BASE.api_game.game_distribution}/${row.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                allianzSection: 'xxx',
                status: 'active',
                section: String(row.section),
                gate: row.gate
            })
        })
            .then((res) => res.json())
            .then((response) => {
                setLoader(false)
                getDistributionData(row.gameId)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const deleteData = (row: any) => {
        setLoader(true)
        fetch(`${BASE.api_game.base_url}${BASE.api_game.game_distribution}/${row.id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                setLoader(false)
                getDistributionData(row.gameId)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    };


    const selectHandler = (gameId: any) => {
        getDistributionData(gameId)
    }


    const [getParcode, setParcode] = useState<any>('')
    const [downloadFile, setDownloadFile] = useState('')

    const downloadTickets = () => {
        fetch(`${BASE.api_game.base_url}${BASE.api_game.tickets}/${getParcode || '20240410'}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api_game.api_key
            },
        })
            .then((resg) => resg.json())
            .then((response) => {
                const worksheet = utils.json_to_sheet(response);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, 'tickets');
                writeFile(workbook, `tickets-${getParcode || '20240410'}.xlsx`);
                setDownloadFile(response.file)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const downloadAccess = () => {
        fetch(`${BASE.api_fc.base_url}${BASE.api_fc.get_access}/${getParcode || '0'}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api_game.api_key
            },
        })
            .then((resg) => resg.json())
            .then((response) => {
              if(response.data.length > 0 ){
                const worksheet = utils.json_to_sheet(response.data);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, 'access');
                writeFile(workbook, `access-${getParcode || '0'}.xlsx`);
                setDownloadFile(response.file)
                // setUserList(response.visitors)
              } else {
                alert('Nenhum acesso para o jogo selectionado')
              } }
            )
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getGameList()
    }, [])


    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Selecione um jogo:</Card.Title>

                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <select
                                className="form-control"
                                onChange={(event) => { selectHandler(event.target.value) }}
                            >
                                {gameList.length > 0 &&
                                    gameList.map((game) => (
                                        <option key={game.id} value={game.id}>
                                            {`${game.home} vs ${game.visitor}`}
                                        </option>
                                    ))
                                }
                            </select>
                        </Form.Group>


                    </Col>
                    <Col xs={12} md={2}>
                        <input
                            className="btn form-control btn-default"
                            type="submit"
                            value="Baixar Tickets"
                            onClick={downloadTickets}
                        />
                    </Col>
                    <Col xs={12} md={2}>
                        <a className="btn form-control btn-default" target='_blank' rel="noreferrer" href={`/reports/${getParcode || '20240410'}.xlsx`}>Baixar Contingência</a>
                    </Col>
                        <Col xs={12} md={2}>
                  <input
                    className="btn form-control btn-default"
                    type="submit"
                    value="Baixar Acessos"
                    onClick={downloadAccess}
                  />
                </Col>
                </Row>


            </Card.Header>
            <Card.Body>
                {loader &&
                    < LoadingHandler />}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Cód. Integração</th>
                            <td>Descrição</td>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 && tableData.map((row) => (
                            <tr key={row.id}>

                                <td>
                                    <Form.Control
                                        type="text"
                                        defaultValue={row.section}
                                        onChange={(event) =>
                                            setTableData(
                                                tableData.map((r) =>
                                                    r.id === row.id
                                                        ? { ...r, section: event.target.value }
                                                        : r
                                                )
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        defaultValue={row.gate}
                                        onChange={(event) =>
                                            setTableData(
                                                tableData.map((r) =>
                                                    r.id === row.id
                                                        ? { ...r, gate: event.target.value }
                                                        : r
                                                )
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <Button variant="primary" onClick={() => updateData(row)}>
                                        Aplicar
                                    </Button>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => deleteData(row)}>
                                        excluir
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Cód. Integração</th>
                                <td>Descrição</td>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Control
                                        type="text"
                                        placeholder="Cód. Integração"
                                        {...register('section')}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        placeholder="Descrição"
                                        {...register('gate')}
                                    />
                                </td>
                            </tr>
                        </tbody></Table>
                    <Button variant="primary" type="submit">
                        Adicionar
                    </Button>
                </Form>
            </Card.Footer>
        </Card>
    )
}

export default GameDistribution
