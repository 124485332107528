import React, { Component, useCallback, useEffect, useRef, useState } from 'react'
import {
  FormControl,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  InputGroup,
  Button,
  Pagination
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

import './reports.sass'
import { Link } from 'react-router-dom'
import { utils, writeFile } from 'xlsx'

function Reports() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const currentUser = sessionStorage.getItem('role')
  const [isLoading, setLoading] = useState(false)
  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }

  const currentDate = new Date();

  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  } as const;

  const [reportList, setReportList] = useState([])
  const [page, setPage] = useState(1);
  const [pageSizeReportDownload, setPageSizeReportDownload] = useState(20);
  const [downloadReport, setDownloadReport] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [formData, setFormData] = useState([]);
  const [startDate, setStartDate] = useState( new Date(currentDate.toLocaleString('en-CA', options)))
  const [endDate, setEndDate] = useState(new Date(currentDate.toLocaleString('en-CA', options)))
  const [disableSign, setDisableSign] = useState(false)

  const gates = [
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.168',
      sync: true
    },
    {
      name: 'Matarazzo',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.195',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.169',
      sync: false
    },
    {
      name: 'Matarazzo',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.196',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.174',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.177',
      sync: false
    },
    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.175',
      sync: false
    },

    {
      name: 'Palestra',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.181',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 2',
      IP: '192.168.0.183',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 1',
      IP: '192.168.0.187',
      sync: false
    },

    {
      name: 'Estacionamento',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.184',
      sync: false
    },
    {
      name: 'Estacionamento',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.186',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Entraram',
      group: 'Catraca 1',
      IP: '192.168.0.197',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.190',
      sync: false
    },{
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.192',
      sync: false
    },
    {
      name: 'Conveniência',
      direction: 'Sairam',
      group: 'Catraca 2',
      IP: '192.168.0.193',
      sync: false
    }
  ]

  const findGate = (gateIP: any) => {
    const currentGate = gates.find((item) => item.IP === gateIP)
    return currentGate ? `${currentGate.name}` : '-'
  }

  const getGates = (gateName: any) => {
    const values: any = [];
    gates.forEach(gate => {
      if (gate.name === gateName) {
        values.push(gate.IP);
      }
    })
    return values;
  }

  const [selectedGates, setSelectedGates] = useState('');

  const handleChange = (event: any) => {
    setSelectedGates(getGates(event.target.value));
  };

  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false

    } as const;

    return eventDate.toLocaleString('pt-br', options)
  }


  const onSubmit = (data: any) => {
    setLoading(true)
    const options = {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false

    } as const;
    setFormData(data)
    const dateFormatPermanenceStart = new Date(startDate.toLocaleString('en-CA', options))
    const dateFormatPermanenceEnd = new Date(endDate.toLocaleString('en-CA', options))
    const gate = selectedGates


    const type = []

    if (data.visitor) {
      type.push('visitor')
    }
    if (data.guest) {
      type.push('guest')
    }
    if (data.member) {
      type.push('member')
    }
    if (data.provider) {
      type.push('provider')
    }
    if (data.employee) {
      type.push('employee')
    }

    fetch(`${BASE.api_report.base_url}${BASE.api_report.report_access}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        page,
        pageSize: pageSize,
        gate: (gate.length > 0) ? gate : null,
        type: (type.length > 0) ? type : null,
        accessCode: data.accessCode ,
        title: data.title ,
        name: data.name ,
        dateFrom:  dateFormatPermanenceStart.toISOString().split('T')[0],
        dateTo:  dateFormatPermanenceEnd.toISOString().split('T')[0],

      })
    })
      .then((resg) => resg.json())
      .then((response) => {

        if(downloadReport) {
          const jsonData: any = []
          for (const item of response.data) {
            jsonData.push(
              {
                data: `${item['dateTime']}`,
                carteirinha: item['accessCode'],
                nome: item['name'],
                tipo: item['cardOrFace'],
                portão: `${findGate(item['terminalIP'])}`,
                direção: item['direction'] === 'Exit' ? 'Saída' : 'Entrada',
                multiclubesStatus: item['multiclubesStatus']=== 'Allow' ? 'Permitido' : 'Negado',
                multiclubesMessage: item['multiclubesMessage']
              })

          }

          const worksheet = utils.json_to_sheet(jsonData);
          const workbook = utils.book_new();
          utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          writeFile(workbook, `report_acessos.xlsx`);
        } else {
          setReportList(response.data)
          setTotalPages(Math.ceil(response.total / response.pageSize))
          setPageSizeReportDownload(parseInt(response.total))

        }
        setLoading(false)
        setDisableSign(false)
      })
      .catch((error) => {
        setLoading(false)
        setDisableSign(false)
      })
  }

  const formRef = useRef(null);


  useEffect(() => {

    onSubmit(formData)

  }, [page]);

  function handlePrev() {
    setPage(page - 1);
  }

  function handleNext() {
    setPage(page + 1);
  }

  function getPageNumbers() {

    const numbers: any = [page];


    if (totalPages <= 6) {
      for (let i = 2; i <= totalPages; i++) {
        numbers.push(i);
      }
    } else {

      for (let i = 2; i <= totalPages - 1; i++) {

        if (i === page - 1) {
          numbers.push(Number(i));
        } else if (i === page + 1) {
          numbers.push(i);
        }
      }
    }


    return numbers.sort(function (a: any, b: any) {
      return a - b;
    });
  }
  const downloadExcel = async () => {
    setDownloadReport(true)
    setPageSize(pageSizeReportDownload)
    onSubmit(formData)
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Pesquisar</Card.Title>
        <span className="d-block m-t-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Tipo de título</Form.Label>
              <Row className='mb-3'>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="visitor"
                    label="Visitante"
                    {...register('visitor')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="guest"
                    label="Convidado"
                    {...register('guest')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="member"
                    label="Associado"
                    {...register('member')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="provider"
                    label="Prestador"
                    {...register('provider')}
                  />
                </Col>
                <Col sm={6} md={2}>
                  <Form.Check
                    type="checkbox"
                    id="employee"
                    label="Funcionário"
                    {...register('employee')}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Row>
                 <Col md={2}>
                <Form.Group controlId="exampleForm.title">
                  <Form.Label>Título</Form.Label>

                  <FormControl
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    {...register('title')}
                  />
                </Form.Group>
              </Col> <Col md={4}>
                <Form.Group controlId="exampleForm.name">
                  <Form.Label>Nome</Form.Label>

                  <FormControl
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    {...register('name')}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="exampleForm.accessCode">
                  <Form.Label>Carteirinha</Form.Label>

                  <FormControl
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    {...register('accessCode')}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Dispositivo</Form.Label>
                  <Form.Control as="select" onChange={handleChange} >
                    <option>Todos</option>
                    <option value="Matarazzo">Matarazzo</option>
                    <option value="Palestra">Palestra</option>
                    <option value="Estacionamento">Estacionamento</option>
                    <option value="Conveniência">Conveniência</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>



              <Col md={4}>
                <Form.Group>
                  <Form.Label>Data de acesso:</Form.Label>
                  <DatePicker
                    className="form-control"
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>

                <Form.Group>
                  <Form.Label>à:</Form.Label>
                  <DatePicker
                    className="form-control"
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Label> .</Form.Label>
                <Form.Group>
                  <Button
                    disabled={disableSign}
                    type="submit"
                    className="w-100"
                    onClick={() => clearErrors()}
                  >
                    Pesquisar
                  </Button>
                   <Link onClick={() => { downloadExcel()  }} className="link-coordinator text-c-blue" to={''}>
                    baixar relatório
                </Link>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </span>
      </Card.Header>
      <Card.Body>
        {isLoading &&
          <div className='local-loader'>
            <i
              className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
              title="oiee"
            />
          </div>}
        <Table responsive>
          <thead>
            <tr>
              <th>Data de acesso:</th>
              <th>Nome</th>
              <th>Carteirinha</th>
              <th>Tipo de Acesso</th>
              <th>Dispositivo</th>
              <th>Direção</th>
              <th>MultiClubes</th>
              <th>Mensagem Multiclubes </th>
            </tr>
          </thead>
          <tbody>
            {reportList &&
              reportList.map((item: any) => (
                <tr key={item.id}>
                  <td>{dateConvert(item.dateTime)}</td>
                  <th scope="row">{item.name}</th>
                  <td>{item.accessCode}</td>
                  <td>{item.cardOrFace}</td>
                  <td>{findGate(item.terminalIP)}</td>
                  <td>{item.direction === 'Exit' ? 'Saída' : 'Entrada'}</td>
                  <td>{item.multiclubesStatus === 'Allow' ? 'Permitido' : 'Negado'}</td>
                  <td>{item.multiclubesMessage}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
      {reportList.length > 0 &&
        <Card.Footer>


          <Pagination className='report-paginate'>
            {/* <Pagination.First disabled={page === 1} onClick={handlePrev} /> */}
            <Pagination.Prev disabled={page === 1} onClick={handlePrev} />
            {
              (page > 1) && (
                <Pagination.Item onClick={() => { setPage(1); getPageNumbers() }} active={page === 1}>{1}</Pagination.Item>
              )}
            {(page > 3) && (
              <Pagination.Ellipsis />
            )
            }

            {getPageNumbers().map((pageItem: any) =>
              <Pagination.Item onClick={() => { setPage(pageItem); getPageNumbers() }} active={page === pageItem} key={pageItem}>{pageItem}</Pagination.Item>
            )}
            {(page < (totalPages - 2)) && (
              <Pagination.Ellipsis />
            )
            }
            {
              (page < totalPages) && (
                <Pagination.Item onClick={() => { setPage(totalPages); getPageNumbers() }} active={page === totalPages}>{totalPages}</Pagination.Item>
              )
            }
            <Pagination.Next disabled={page === totalPages} onClick={handleNext} />
            {/* <Pagination.Last disabled={page === totalPages} onClick={handleNext} /> */}
          </Pagination>
        </Card.Footer>
      }
    </Card>
  )
}

export default Reports
